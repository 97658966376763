<template>
  <div>
    <div>
      <div
        style="
          height: 40px;
          margin-top: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16pt;
        "
      >
        {{ repair.RepairNo }}
      </div>
      <el-select
        v-model="LanguageId"
        style="position: absolute; top: 10px; right: 5px; width: 80px"
        size="small"
        @change="CheckLanguage"
      >
        <el-option
          v-for="item in Languages"
          :key="item.Id"
          :label="item.Name"
          :value="item.Id"
        />
      </el-select>
      <div>
        <div v-for="item in repair.RepairMobileInfos" :key="item.Id + 64">
          <el-divider content-position="left">
            {{ item.RepairstatusFormat }}</el-divider
          >
          <div class="fl-grow">
            <p>
              {{ tools.GetLanguageValue("web.品牌") }}/{{
                tools.GetLanguageValue("web.型号")
              }}:{{ item.BrandAndModel }} {{ item.ModelPropertiesFormat }}
            </p>
            <p>
              {{ tools.GetLanguageValue("web.维修时间") }}:
              {{ item.RepairStartTimeFormat }}
            </p>
            <p>{{ item.ModelName }}</p>

            <p>{{ item.Comment }}</p>
          </div>

          <el-steps
          
            align-center
            finish-status="success" 
            direction ='vertical'
            :active="item.Records.Count"
          >
            <el-step
              :title="item.CreateTimeFormat " :icon="CircleCheck"
                :description="item.Msg"
              v-for="(item, index) in item.Records"
              :key="index"
            />
          </el-steps>
        </div>
      </div>
    </div>
  </div>
</template>
  <script setup>
import { ref, onMounted, onBeforeMount } from "vue";
import axios from "../../../commons/AxiosMethod.js";
import tools from "../../../commons/tools.js";
import navigation from "../../../components/Modules/Navigation.vue";
import { ElMessageBox, ElMessage } from "element-plus";
import { useRoute } from "vue-router";
import { CircleCheck, Close, Refresh, Message } from "@element-plus/icons-vue";

const route = useRoute();
const ContentHeight = ref(document.documentElement.clientHeight - 130);

// 页面加载前
onBeforeMount(() => {});

const repairId = ref("");

// 页面加载
onMounted(() => {
  repairId.value = route.query.repairId;

  GetLanguageList();
  getRepairInfo();
});

const LanguageId = ref(localStorage.getItem("LanguageId"));
const Languages = ref({});
const LanguageOpt = ref({});
//语言的列表
const GetLanguageList = () => {
  const param = {};
  axios.apiMethod("/all/Language/GetLanguageList", "get", param, (result) => {
    Languages.value = result.Data;
    Languages.value.forEach((element) => {
      if (element.Id == LanguageId.value) {
        LanguageOpt.value = element;
      }
    });
    if (LanguageId.value == "" || LanguageId.value == null) {
      Languages.value.forEach((element) => {
        if (element.Default) {
          LanguageOpt.value = element;
          localStorage.setItem("LanguageId", element.Id);
          tools.GetLanguageById(element.Id, () => {
            window.location.href = window.location.href;
          });
        }
      });
    }
  });
};

//选择语言
const CheckLanguage = (Id) => {
  localStorage.setItem("LanguageId", Id);
  Languages.value.forEach((element) => {
    if (element.Id == Id) {
      LanguageOpt.value = element;
      tools.GetLanguageById(element.Id, () => {
        window.location.href = window.location.href;
      });
    }
  });
  GetLanguageList();
};

const repair = ref({});
// 获取维修单信息
const getRepairInfo = () => {
  axios.apiMethod(
    "/Home/GetRepairById",
    "get",
    {
      id: repairId.value,
    },
    (result) => {
      repair.value = result.Data;
    }
  );
};
</script>
  <style>
fieldset {
  border: 2px solid #e9e9e9;
  border-radius: 5px;
  padding: 16px;
}
fieldset legend {
  padding: 0 8px !important;
  font-size: 16px !important;
  width: auto !important;
  border: none !important;
  margin-bottom: 0 !important;
}
</style>
  